import React from 'react';
import { RegisterForm } from '../components/register-form';


function RegisterPage() {
    return (
      <>
        <RegisterForm />
      </>
    );
};

export default RegisterPage;