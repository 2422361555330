import React from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
} from 'mdb-react-ui-kit';
import { OtherIncomeSummary } from '../components/otherIncomeSummary';
import { OtherIncomeTable } from '../components/otherIncomeTable';

function OtherIncomePage() {
    return (
      <>
        <div className='d-flex justify-content-center my-5' >
          <MDBCard style={{width: '80%'}}>
            <MDBCardBody>
              <MDBCardTitle className='mb-4'>Podsumowanie</MDBCardTitle>
              <OtherIncomeSummary />
              <hr className="hr"/>
              <MDBCardTitle className='mb-4'>Lista pozostałych przychodów</MDBCardTitle>
              <OtherIncomeTable />
            </MDBCardBody>
          </MDBCard>
        </div>
      </>
    );
}

export default OtherIncomePage;
