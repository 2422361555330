// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-imported-file {
  color: rgb(185, 185, 185) !important;
  opacity: 0.75 !important;
  pointer-events: none !important;
}

.sidenav-link {
  border-radius: 0px !important;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/navbar.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,wBAAwB;EACxB,+BAA+B;AACjC;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".no-imported-file {\n  color: rgb(185, 185, 185) !important;\n  opacity: 0.75 !important;\n  pointer-events: none !important;\n}\n\n.sidenav-link {\n  border-radius: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
