import React, {useEffect, useState} from 'react';
import {
  MDBInput
} from 'mdb-react-ui-kit';
import '../assets/styles/import-form.css';

export function OptionTradesSummary({country}) {
  const trades = JSON.parse(localStorage.getItem('importOptionTrades'));
  var gain = 0;
  if (trades != null) {
    gain = trades[country].netIncomePln.toFixed(2);
  }

  return (
    <div className='mb-4'>
      <div className='row my-4'>
        <div className='col-3 mt-2 pe-0'>Dochód [PLN]:</div>
        <div className='col-2 ps-0'><MDBInput style={{backgroundColor: gain >= 0 ? 'lightgreen' : '#FFCCCB'}} id='gain' type='number' value={gain} readOnly={true}/></div>
      </div>
    </div>
  );
}
