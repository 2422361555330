import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  MDBInput,
  MDBCol,
  MDBRow,
  MDBCheckbox,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBAlert
} from 'mdb-react-ui-kit';
import {v4 as uuidv4} from "uuid";

export function LoginForm() {
  const [accountActivated, setAccountActivated] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [notLoggedIn, setNotLoggedIn] = useState(false);
  const [errMessage, setErrMessage] = useState(null);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const userRegistered = params.get('registered');

  const signIn = (e) => {
    e.preventDefault();
    const loginForm = document.getElementById('login-form');
    const formData = new FormData(loginForm);

    var myHeaders = new Headers();
        myHeaders.append("Authorization", "Basic cHprOm1kYg==");
        myHeaders.append("X-ReqID", uuidv4().toString());
        myHeaders.append("Content-Type", "application/json");

    const loginData = {
      email: formData.get('email'),
      password: formData.get('password'),
    };

    localStorage.setItem('userId', loginData.email);

    fetch('https://ibkrpit.pl/v1/user/login', {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(loginData),
      redirect: 'follow'
    })
      .then((response) => response.status)
      .then((result) => {
        if ( result === 200 ) {
            localStorage.setItem('token', true);
            // localStorage.setItem('userId', loginData.email);
            setLoggedIn((prev) => true);
            setNotLoggedIn((prev) => false);
            setErrMessage( null );
            return window.location.href = '/tutorial';
        }
        if ( result === 403 || result === 404 ) {
            localStorage.setItem('token', false);
            // localStorage.setItem('userId', null);
            setLoggedIn((prev) => false);
            setNotLoggedIn((prev) => true);
            setErrMessage( 'Niepoprawny adres e-mail lub hasło' );
            return window.location.href = '#';
        }
        localStorage.setItem('token', false);
        // localStorage.setItem('userId', null);
        setLoggedIn((prev) => false);
        setNotLoggedIn((prev) => true);
        setErrMessage( 'Błąd serwera, spróbuj później' );
        return window.location.href = '#';
      })
      .catch((error) => {
        localStorage.setItem('token', false);
        // localStorage.setItem('userId', null);
        setLoggedIn((prev) => false);
        setNotLoggedIn((prev) => true);
        setErrMessage( 'Błąd serwera: ' + error.message );
    });
  };

    return(
      <div className='d-flex justify-content-center mt-5'>
      <MDBCard style={{minWidth: '33%'}}>
        <MDBCardBody>
          <form id='login-form' onSubmit={(e) => signIn(e)}>
            <MDBInput className='mb-4' type='email' id='loginInput' name='email' label='E-mail' />
            <MDBInput className='mb-4' type='password' id='passwordInput' name='password' label='Hasło' />
      
            <MDBRow className='mb-4'>
              <MDBCol className='d-flex justify-content-center'>
                <MDBCheckbox id='stay-loggedin' label='Zapamiętaj mnie' defaultChecked />
              </MDBCol>
              {/*<MDBCol>*/}
              {/*  <a href='#!'>Zapomniałem/am hasła?</a>*/}
              {/*</MDBCol>*/}
            </MDBRow>
      
            <MDBBtn type='submit' className='mb-4' block>
              Zaloguj
            </MDBBtn>
      
            <div className='text-center'>
              <p>
                Nie masz konta? <Link to='/register'>Zarejestruj się</Link>
              </p>
            </div>
          </form>
        </MDBCardBody>
      </MDBCard>
      <MDBAlert
        color='success'
        autohide
        position='top-right'
        delay={2000}
        appendToBody
        open={loggedIn}
        onClose={() => setLoggedIn(false)}
      >
        Udało się zalogować
      </MDBAlert>
      {userRegistered &&
      <MDBAlert
          color='success'
          autohide
          position='top-right'
          delay={5000}
          appendToBody
          dismissBtn
          open={accountActivated}
          onClose={() => setAccountActivated( false )}
      >
        Aktywowano konto
      </MDBAlert>
      }
      <MDBAlert
        color='danger'
        autohide
        position='top-right'
        delay={5000}
        appendToBody
        open={notLoggedIn}
        onClose={() => setNotLoggedIn(false)}
      >
        {errMessage}
      </MDBAlert>
    </div>
    )
}
