import React from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
} from 'mdb-react-ui-kit';
import ImportForm from '../components/importForm';

function TutorialPage() {
    return (
      <>
        <div className='d-flex justify-content-center my-5' >
          <MDBCard style={{width: '80%'}}>
            <MDBCardBody>
              <MDBCardTitle className='mb-4'>Import</MDBCardTitle>
              <ImportForm />
            </MDBCardBody>
          </MDBCard>
        </div>
      </>
    );
}

export default TutorialPage;
