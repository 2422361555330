import React from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
} from 'mdb-react-ui-kit';
import { BondCouponsSummary } from '../components/bondCouponsSummary';
import { BondCouponsTable } from '../components/bondCouponsTable';

function DividendsPage() {
    return (
      <>
        <div className='d-flex justify-content-center my-5' >
          <MDBCard style={{width: '80%'}}>
            <MDBCardBody>
              <MDBCardTitle className='mb-4'>Podsumowanie</MDBCardTitle>
              <BondCouponsSummary />
              <hr className="hr"/>
              <MDBCardTitle className='mb-4'>Lista otrzymanych kuponów</MDBCardTitle>
              <BondCouponsTable />
            </MDBCardBody>
          </MDBCard>
        </div>
      </>
    );
}

export default DividendsPage;
