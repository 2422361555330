import React, {useState, useEffect} from 'react';
import { MDBDatatable } from 'mdb-react-ui-kit';

export function BondCouponsTable() {
  const [advancedData, setAdvancedData] = useState({
    columns: [
      { label: 'ID', field: 'id', sort: false },
      { label: 'Data operacji', field: 'paymentDate', sort: true },
      { label: 'Symbol', field: 'symbol', sort: false },
      { label: 'Wartość brutto', field: 'amount', sort: false },
      { label: 'Waluta', field: 'currency', sort: false },
      { label: 'Podatek u źródła', field: 'withholdingTax', sort: false },
      { label: 'Data przeliczenia kursu', field: 'bookingDate', sort: false },
      { label: 'Kurs waluty', field: 'exchangeRatePln', sort: false },
      { label: 'Przychód brutto [PLN]', field: 'grossIncomePln', sort: false },
      { label: 'Podatek u źródła [PLN]', field: 'withholdingTaxPln', sort: false },
      { label: 'Podatek należny [PLN]', field: 'taxDuePln', sort: false },
      { label: 'Podatek do dopłaty [PLN]', field: 'taxSurchargePln', sort: false },
      { label: 'Opis', field: 'description', sort: false },
    ],
    rows: [],
  });

  useEffect(() => {
    const bondCoupons = JSON.parse(localStorage.getItem('importBondCoupons'));
    if (bondCoupons == null) {
      return;
    }

    const printNumber = function(value) {
      return value === 0 ? '0.0' : value;
    };

    const datatable = [];
    bondCoupons.coupons.forEach((coupon) => {
      datatable.push({
        id: coupon.id || '',
        paymentDate: coupon.paymentDate || '',
        symbol: coupon.symbol || '',
        amount: coupon.amount || '',
        currency: coupon.currency || '',
        withholdingTax: printNumber(coupon.withholdingTax) || '',
        bookingDate: coupon.bookingDate || '',
        exchangeRatePln: coupon.exchangeRatePln || '',
        grossIncomePln: printNumber(coupon.grossIncomePln) || '',
        withholdingTaxPln: printNumber(coupon.withholdingTaxPln) || '',
        taxDuePln: printNumber(coupon.taxDuePln) || '',
        taxSurchargePln: printNumber(coupon.taxSurchargePln) || '',
        description: coupon.description || ''
      })
    });
    setAdvancedData((prevData) => ({
      ...prevData,
      rows: datatable
    }));
  }, []);

  return (
    <MDBDatatable
      data={advancedData}
      loadingMessage='Ładowanie danych...'
      noFoundMessage='Brak wyników'
      ofText='z'
      rowsText='Wiersze na stronę'
      advancedSearch={(value) => {
        const string = value.split(' in:').map((str) => str.trim());
        const phrase = string[0];
        let columns = string[1];
        if (columns) {
          columns = columns.split(',').map((str) => str.toLowerCase().trim());
        }
        return { phrase, columns };
      }}
    />
  );
}
