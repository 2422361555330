import React, { useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  MDBFile,
  MDBBtn,
  MDBCollapse,
  MDBCheckbox,
  MDBInput,
  MDBLoadingManagement,
  MDBIcon,
  MDBAlert,
  MDBRadio,
} from 'mdb-react-ui-kit';
import '../assets/styles/import-form.css';

export default function ImportForm() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [bookingDate, setBookingDate] = useState('D_MINUS_1');
  const [isOpen, setIsOpen] = useState(false);
  const [importComplete, setImportComplete] = useState(false);
  const [importError, setImportError] = useState(false);
  const fontRef = useRef(null);
  const [importLoading, setImportLoading] = useState(false);
  const [errMessage, setErrMessage] = useState(null);
  const [warnMessage, setWarnMessage] = useState(null);

  const toggleOpen = () => setIsOpen(!isOpen);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
 }

  const importFiles = async (e) => {
    e.preventDefault();
    setImportLoading(true);
    const importForm = document.getElementById('import-form');
    const formData = new FormData(importForm);
    let formToSend = {
      year: formData.get('year'),
      trades: [],
      cashOperations: [],
      options: {
        "bookingDateChoice": bookingDate
      },
    };

    const tradeFiles = document.querySelectorAll('input[id="trades-files"]');
    const cashFiles = document.querySelectorAll('input[id="cash-files"]');
    var containsFiles = false;

    for (const fileInput of tradeFiles) {
      const files = fileInput.files;
      for (let i = 0; i < files.length; i++) {
        containsFiles = true;
        try {
          const base64Data = await getBase64(files[i]);
          formToSend.trades.push({ name: files[i].name, content: base64Data });
        } catch (error) {
          console.error('Error converting file to base64:', error);
        }
      }
    }

    for (const fileInput of cashFiles) {
      const files = fileInput.files;
      for (let i = 0; i < files.length; i++) {
        containsFiles = true;
        try {
          const base64Data = await getBase64(files[i]);
          formToSend.cashOperations.push({ name: files[i].name, content: base64Data });
        } catch (error) {
          console.error('Error converting file to base64:', error);
        }
      }
    }

    if ( ! containsFiles ) {
      setImportLoading( false );
      setErrMessage( 'Nie przesłano żadnego pliku z raportem.' );
      setImportError((prev) => true);
      return;
    }

    localStorage.setItem('importDoneToken', '');
    localStorage.setItem('importYear', '1970');
    var requestId = uuidv4().toString();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic cHprOm1kYg==");
    myHeaders.append("X-ReqID", requestId);
    myHeaders.append("X-UserID", localStorage.getItem('userId'));
    myHeaders.append("Content-Type", "application/json");
    const requestBody = JSON.stringify(formToSend);
    fetch('https://ibkrpit.pl/v1/broker/ibkr/calculate-tax', {
      method: 'POST',
      headers: myHeaders,
      body: requestBody,
      redirect: 'follow'
    })
      .then((res) => {
          return res.json()
              .then((json) => {
                  if (res.ok) {
                      return Promise.resolve(json)
                  }
                  return Promise.reject(json);
          });
      })
      .then((data) => {
        setImportLoading(false);
        setImportComplete((prev) => true);
        setErrMessage( null );
        setImportError((prev) => false);
        localStorage.setItem('requestBody', requestBody);
        localStorage.setItem('importDoneToken', 'true');
        localStorage.setItem('importYear', formData.get('year'));
        localStorage.setItem('importEquityTrades', JSON.stringify(data.equityTrades));
        if (data.equityTrades != null) {
            localStorage.setItem('equityTradesFirstCountry', Object.keys(data.equityTrades)[0])
        }
        localStorage.setItem('importDividends', JSON.stringify(data.dividends));
        localStorage.setItem('importBondTrades', JSON.stringify(data.bondTrades));
        if (data.bondTrades != null) {
          localStorage.setItem('bondTradesFirstCountry', Object.keys(data.bondTrades)[0])
        }
        localStorage.setItem('importBondCoupons', JSON.stringify(data.bondCoupons));
        localStorage.setItem('importOtherIncome', JSON.stringify(data.otherIncome));
        localStorage.setItem('importOtherFee', JSON.stringify(data.otherFee));
        localStorage.setItem('importOptionTrades', JSON.stringify(data.optionTrades));
        if (data.optionTrades != null) {
          localStorage.setItem('optionTradesFirstCountry', Object.keys(data.optionTrades)[0])
        }
        if (data.processingWarnings != null) {
            var i = 0;
            const warnItems = data.processingWarnings.map((line) => <li key={'line-' + i++}>{line}</li>);
            setWarnMessage(warnItems);
            return window.location.href = '#';
        }
        return window.location.href = '/summary';
      })
      .catch((err) => {
        localStorage.setItem('importDoneToken', '');
        setImportLoading( false );
        setImportComplete((prev) => false);
        setErrMessage( `Błąd serwera (ID: ${requestId}): ${err.message}` );
        setImportError((prev) => true);
      });
  };

  return (
    <>
      <form id='import-form' onSubmit={(e) => importFiles(e)}>
        Formularz umożliwia wygenerowanie raportu podatkowego, który ułatwia wypełnienie deklaracji PIT-38, na podstawie
        raportów wygenerowanych z portalu klienta Interactive Brokers. Instrukcje jak wygenerować raporty wejściowe,
        znajdują się na stronie <a href="/tutorial">Dokumentacja</a>.
        <p/>
        <div className='d-flex mb-3 align-items-center w-auto import-year-div'>
          <div className='me-2'>Rok podatkowy: </div>
          <MDBInput min="1900" max={currentYear} defaultValue={currentYear-1} step="1" name='year' id='year-input' type='number' />
        </div>
        <div>
          Lista raportów przeprowadzonych transakcji i zdarzeń korporacyjnych za dany rok podatkowy i lata ubiegłe
          <MDBFile className='me-4 mt-2 mb-4' multiple name='transaction-files' id='trades-files' />
        </div>
        <div>
          Lista raportów przepływów pieniężnych za dany rok podatkowy i lata ubiegłe
          <MDBFile className='me-4 mt-2 mb-4' multiple name='operationInCash-files' id='cash-files' />
        </div>
        <div>
          <MDBBtn tag='a' className='w-100' onClick={toggleOpen}>Opcje zaawansowane</MDBBtn>
          <MDBCollapse open={isOpen} className='mt-3'>
            {/*<MDBCheckbox name='flexCheck1' value='' id='1' label='Option 1' />*/}
            <MDBRadio name='bookingDate' id='bookingDateMinusOne' label='Transakcje walutowe rozlicz po średnim kursie NBP z ostatniego dnia roboczego poprzedzającego zawarcie transakcji na giełdzie (tzw. D-1)'
                      value='D_MINUS_1' checked={bookingDate === 'D_MINUS_1'} onChange={event => {setBookingDate(event.target.value)}} />
            <MDBRadio name='bookingDate' id='bookingDatePlusOne' label='Transakcje walutowe rozlicz po średnim kursie NBP z ostatniego dnia roboczego poprzedzającego rozliczenie transakcji (tzw. D+1)'
                      value='D_PLUS_1' checked={bookingDate === 'D_PLUS_1'} onChange={event => {setBookingDate(event.target.value)}} />
          </MDBCollapse>
        </div>
        <div className='d-flex justify-content-end mt-4'>
          {localStorage.getItem('token') ? (
          <MDBBtn type='submit' className='mb-4 w-auto' block>
            Import
          </MDBBtn>
          ) : (
            <MDBBtn
            type='button'
            className='mb-4 w-auto'
            block
            onClick={() => { window.location = '/'; }} // Redirect to the login page
          >
            Zaloguj
          </MDBBtn>
          )
          }
        </div>
      </form>
      <div className={`${importLoading ? 'mb-5' : 'd-none mb-5'}`} ref={fontRef}>
        <MDBLoadingManagement
          spinnerElement={<MDBIcon className='loading-icon' fas icon='spinner' size='2x' spin />}
          parentRef={fontRef}
        />
      </div>
      <MDBAlert
        color='success'
        autohide
        position='top-right'
        delay={2000}
        appendToBody
        open={importComplete}
        onClose={() => setImportComplete(false)}
      >
        Dane zaimportowano pomyślnie
      </MDBAlert>
      <MDBAlert
        color='warning'
        position='top-right'
        appendToBody
        open={importError}
        dismissBtn={true}
        onClick={() => setImportError(false)}
      >
        {errMessage}
      </MDBAlert>
      {warnMessage != null &&
      <div className="note note-warning mb-3">
          <strong>Lista błędów zaistniałych podczas generowania raportu:</strong>
          <ul>{warnMessage}</ul>
          <div className="text-center">
            <button type="button" className="btn btn-warning" onClick={() => { window.location = '/summary'; }} data-mdb-ripple-init>Kontynuuj</button>
          </div>
      </div>
    }
    </>
  );
}
