import React, {useEffect, useState} from 'react';
import {
  MDBInput
} from 'mdb-react-ui-kit';
import '../assets/styles/import-form.css';

export function OtherIncomeSummary() {
  const otherIncome = JSON.parse(localStorage.getItem('importOtherIncome'));
  var incomePln = 0;
  if (otherIncome != null) {
    incomePln = otherIncome.incomePln.toFixed(2);
  }

  return (
    <div className='mb-4'>
      <div className='row my-4'>
        <div className='col-3 mt-2 pe-0'>Pozostałe przychody [PLN]:</div>
        <div className='col-2 ps-0'><MDBInput style={{backgroundColor: 'lightgreen'}} id='incomePln' type='number' value={incomePln} readOnly={true}/></div>
      </div>
    </div>
  );
}
