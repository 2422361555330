import React, {useEffect, useState} from 'react';
import {
  MDBInput
} from 'mdb-react-ui-kit';
import '../assets/styles/import-form.css';

export function OtherFeesSummary() {
  const otherFees = JSON.parse(localStorage.getItem('importOtherFee'));
  var costPln = 0;
  if (otherFees != null) {
    costPln = otherFees.costPln.toFixed(2);
  }

  return (
    <div className='mb-4'>
      <div className='row my-4'>
        <div className='col-3 mt-2 pe-0'>Pozostałe koszty [PLN]:</div>
        <div className='col-2 ps-0'><MDBInput style={{backgroundColor: '#FFCCCB'}} id='costPln' type='number' value={costPln} readOnly={true}/></div>
      </div>
    </div>
  );
}
