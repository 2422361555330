import React, { useState } from 'react';
import {
  MDBInput,
  MDBCard,
  MDBBtn,
  MDBCardBody,
  MDBAlert,
} from 'mdb-react-ui-kit';
import {v4 as uuidv4} from "uuid";

export function RegisterForm() {
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [registerFailure, setRegisterFailure] = useState(false);
  const [errMessage, setErrMessage] = useState(null);

  const signUp = (e) => {
    e.preventDefault();
    const registerForm = document.getElementById('register-form');
    const formData = new FormData(registerForm);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic cHprOm1kYg==");
    myHeaders.append("X-ReqID", uuidv4().toString());
    myHeaders.append("Content-Type", "application/json");

    const registerData = {
      email: formData.get('email'),
      password: formData.get('password'),
    };

    if (formData.get('password') !== formData.get('repeat-password')) {
       setRegisterSuccess((prev) => false);
       setRegisterFailure((prev) => true);
       setErrMessage((prev) => 'Hasła nie są takie same');
    } else {
      fetch('https://ibkrpit.pl/v1/user/register', {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(registerData),
        redirect: 'follow'
      })
      .then((response) => response.status)
      .then((result) => {
          if ( result === 200 ) {
              setRegisterSuccess((prev) => true);
              setRegisterFailure((prev) => false);
              setErrMessage((prev) => null);
          } else if ( result === 409 ) {
              setRegisterSuccess((prev) => false);
              setRegisterFailure((prev) => true);
              setErrMessage( 'Podany adres e-mail jest już zarejestrowany' );
          } else {
              setRegisterSuccess((prev) => false);
              setRegisterFailure((prev) => true);
              setErrMessage( 'Błąd serwera, spróbuj później' );
          }
      })
      .catch(error => {
          setRegisterSuccess((prev) => false);
          setRegisterFailure((prev) => true);
          setErrMessage( 'Błąd serwera: ' + error.message );
      });
    }
  };

  return (
    <>
      <div className='d-flex justify-content-center mt-5'>
        <MDBCard style={{width: '33%'}}>
          <MDBCardBody>
            <form id="register-form" onSubmit={(e) => {signUp(e)}}>
              <MDBInput className='mb-4' type='email' id='register-email' name='email' label='E-mail' required/>
              <MDBInput className='mb-4' type='password' id='register-password' name='password' label='Hasło' required/>
              <MDBInput className='mb-4' type='password' id='register-repeat-password' name='repeat-password' label='Powtórz hasło' />
              <MDBBtn type='submit' className='mb-2' block>Rejestracja</MDBBtn>
              <MDBBtn type='reset' className='mb-4' onClick={() => { window.location = '/'; }} block>Anuluj</MDBBtn>
            </form>
          </MDBCardBody>
        </MDBCard>
      </div>
      <MDBAlert
        color='success'
        autohide
        position='top-right'
        delay={2000}
        appendToBody
        open={registerSuccess}
        onClose={() => setRegisterSuccess(false)}
      >
        Na podany adres e-mail wysłano link aktywujący
      </MDBAlert>
      <MDBAlert
        color='danger'
        autohide
        position='top-right'
        delay={2000}
        appendToBody
        open={registerFailure}
        onClose={() => setRegisterFailure(false)}
      >
        {errMessage}
      </MDBAlert>
   </>
  );
}
