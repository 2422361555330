import React, {useState, useEffect} from 'react';
import { MDBDatatable } from 'mdb-react-ui-kit';

export function DividendsTable() {
  const [advancedData, setAdvancedData] = useState({
    columns: [
      { label: 'ID', field: 'id', sort: false },
      { label: 'Data operacji', field: 'paymentDate', sort: true },
      { label: 'Symbol', field: 'symbol', sort: false },
      { label: 'Wartość brutto', field: 'amount', sort: false },
      { label: 'Waluta', field: 'currency', sort: false },
      { label: 'Podatek u źródła', field: 'withholdingTax', sort: false },
      { label: 'Data przeliczenia kursu', field: 'bookingDate', sort: false },
      { label: 'Kurs waluty', field: 'exchangeRatePln', sort: false },
      { label: 'Przychód brutto [PLN]', field: 'grossIncomePln', sort: false },
      { label: 'Podatek u źródła [PLN]', field: 'withholdingTaxPln', sort: false },
      { label: 'Podatek należny [PLN]', field: 'taxDuePln', sort: false },
      { label: 'Podatek do dopłaty [PLN]', field: 'taxSurchargePln', sort: false },
      { label: 'Opis', field: 'description', sort: false },
    ],
    rows: [],
  });

  useEffect(() => {
    const dividends = JSON.parse(localStorage.getItem('importDividends'));
    if (dividends == null) {
      return;
    }

    const printNumber = function(value) {
      return value === 0 ? '0.0' : value;
    };

    const datatable = [];
    dividends.dividendPayments.forEach((dividend) => {
      datatable.push({
        id: dividend.id || '',
        paymentDate: dividend.paymentDate || '',
        symbol: dividend.symbol || '',
        amount: dividend.amount || '',
        currency: dividend.currency || '',
        withholdingTax: printNumber(dividend.withholdingTax) || '',
        bookingDate: dividend.bookingDate || '',
        exchangeRatePln: dividend.exchangeRatePln || '',
        grossIncomePln: printNumber(dividend.grossIncomePln) || '',
        withholdingTaxPln: printNumber(dividend.withholdingTaxPln) || '',
        taxDuePln: printNumber(dividend.taxDuePln) || '',
        taxSurchargePln: printNumber(dividend.taxSurchargePln) || '',
        description: dividend.description || ''
      })
    });
    setAdvancedData((prevData) => ({
      ...prevData,
      rows: datatable
    }));
  }, []);

  return (
    <MDBDatatable
      data={advancedData}
      loadingMessage='Ładowanie danych...'
      noFoundMessage='Brak wyników'
      ofText='z'
      rowsText='Wiersze na stronę'
      advancedSearch={(value) => {
        const string = value.split(' in:').map((str) => str.trim());
        const phrase = string[0];
        let columns = string[1];
        if (columns) {
          columns = columns.split(',').map((str) => str.toLowerCase().trim());
        }
        return { phrase, columns };
      }}
    />
  );
}
