import React, { useState } from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane
} from 'mdb-react-ui-kit';
import { EquityTradesSummary } from '../components/equityTradesSummary';
import { EquityTradesTable } from '../components/equityTradesTable';

function EquityTradesPage() {
    const [basicActive, setBasicActive] = useState(localStorage.getItem('equityTradesFirstCountry'));

    const handleBasicClick = (value) => {
      if (value === basicActive) {
        return;
      }
      setBasicActive(value);
    };

    const trades = JSON.parse(localStorage.getItem('importEquityTrades'));
    if (trades == null) {
      return (
        <>
        <div className='d-flex justify-content-center my-5' >
            <MDBCard style={{width: '80%'}}>
                <MDBCardBody>
                    <MDBCardTitle className='mb-4'>Podsumowanie</MDBCardTitle>
                    <EquityTradesSummary country={'US'}/>
                    <hr className="hr"/>
                    <MDBCardTitle className='mb-4'>Lista zamkniętych transakcji</MDBCardTitle>
                    <EquityTradesTable country={'US'}/>
                </MDBCardBody>
            </MDBCard>
        </div>
        </>
      );
    }
    const tabLink = [];
    const tabBody = [];
    Object.keys(trades).forEach((country) => {
      tabLink.push(
          <MDBTabsItem key={country}>
            <MDBTabsLink onClick={() => handleBasicClick(country)} active={basicActive === country}>
              {country}
            </MDBTabsLink>
          </MDBTabsItem>
      );
    });
    Object.keys(trades).forEach((country) => {
      tabBody.push(
          <MDBTabsPane open={basicActive === country} key={country}>
            <div className='d-flex justify-content-center my-5' >
              <MDBCard style={{width: '80%'}}>
                <MDBCardBody>
                  <MDBCardTitle className='mb-4'>Podsumowanie</MDBCardTitle>
                  <EquityTradesSummary country={country}/>
                  <hr className="hr"/>
                  <MDBCardTitle className='mb-4'>Lista zamkniętych transakcji</MDBCardTitle>
                  <EquityTradesTable country={country}/>
                </MDBCardBody>
              </MDBCard>
            </div>
          </MDBTabsPane>
      );
    });

    return (
      <>
      <MDBTabs className='mb-3'>
        {tabLink}
      </MDBTabs>
      <MDBTabsContent>
        {tabBody}
      </MDBTabsContent>
      </>
    );
}

export default EquityTradesPage;
