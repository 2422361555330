import React, {useState, useEffect} from 'react';
import { MDBDatatable } from 'mdb-react-ui-kit';

export function OtherIncomeTable() {
  const [advancedData, setAdvancedData] = useState({
    columns: [
      { label: 'ID', field: 'id', sort: false },
      { label: 'Data operacji', field: 'paymentDate', sort: true },
      { label: 'Symbol', field: 'symbol', sort: false },
      { label: 'Wartość brutto', field: 'amount', sort: false },
      { label: 'Waluta', field: 'currency', sort: false },
      { label: 'Data przeliczenia kursu', field: 'bookingDate', sort: false },
      { label: 'Kurs waluty', field: 'exchangeRatePln', sort: false },
      { label: 'Przychód brutto [PLN]', field: 'grossIncomePln', sort: false },
      { label: 'Opis', field: 'description', sort: false },
    ],
    rows: [],
  });

  useEffect(() => {
    const otherIncome = JSON.parse(localStorage.getItem('importOtherIncome'));
    if (otherIncome == null) {
      return;
    }

    const printNumber = function(value) {
      return value === 0 ? '0.0' : value;
    };

    const datatable = [];
    otherIncome.entries.forEach((entry) => {
      datatable.push({
        id: entry.id || '',
        paymentDate: entry.paymentDate || '',
        symbol: entry.symbol || '',
        amount: entry.amount || '',
        currency: entry.currency || '',
        bookingDate: entry.bookingDate || '',
        exchangeRatePln: entry.exchangeRatePln || '',
        grossIncomePln: printNumber(entry.grossIncomePln) || '',
        description: entry.description || ''
      })
    });
    setAdvancedData((prevData) => ({
      ...prevData,
      rows: datatable
    }));
  }, []);

  return (
    <MDBDatatable
      data={advancedData}
      loadingMessage='Ładowanie danych...'
      noFoundMessage='Brak wyników'
      ofText='z'
      rowsText='Wiersze na stronę'
      advancedSearch={(value) => {
        const string = value.split(' in:').map((str) => str.trim());
        const phrase = string[0];
        let columns = string[1];
        if (columns) {
          columns = columns.split(',').map((str) => str.toLowerCase().trim());
        }
        return { phrase, columns };
      }}
    />
  );
}
