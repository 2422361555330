import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { NavbarItem } from './components/navbar';
import Homepage from './pages/home-page';
import RegisterPage from './pages/register-page';
import TutorialPage from './pages/tutorial-page';
import ImportPage from './pages/import-page';
import EquityTradesPage from './pages/equity-trades-page';
import BondTradesPage from './pages/bond-trades-page';
import DividendsPage from './pages/dividends-page';
import BondCouponsPage from './pages/bond-coupons-page';
import OptionTradesPage from './pages/option-trades-page';
import OtherFeesPage from './pages/other-fees-page';
import OtherIncomePage from './pages/other-income-page';
import SummaryPage from './pages/summary-page';

// TODO General:
//       - Bardziej rozroznic kolorem czcionki nieaktywne elementy w navbarze?
//       - Na formularzu rejestracji i logowania poszerzyc MDBInput. Nie udalo mi sie samemu :(. 
// TODO: Import page:
//       - Akcje / Dywidendy w Imporcie aby byly ladnie wyrownane (przy duzej rozdzielczosci jedno jest max po lewej, drugie pop rawej)
function App() {
  const [content, setContent] = React.useState(false);
  const contentRef = React.useRef();

  React.useEffect(() => {
    setContent(contentRef.current);
  }, []);
  return (
    <Router basename='/'>
      <NavbarItem sidenavContent={content}/>
      <div ref={contentRef} >
        <Routes>
          <Route path="/" element={<Homepage />}/>
          <Route path="/register" element={<RegisterPage />}/>
          <Route path="/tutorial" element={<TutorialPage />}/>
          <Route path="/import" element={<ImportPage />}/>
          <Route path="/equity-trades" element={<EquityTradesPage />}/>
          <Route path="/dividends" element={<DividendsPage />}/>
          <Route path="/bond-trades" element={<BondTradesPage />}/>
          <Route path="/bond-coupons" element={<BondCouponsPage />}/>
          <Route path="/option-trades" element={<OptionTradesPage />}/>
          <Route path="/other-fees" element={<OtherFeesPage />}/>
          <Route path="/other-income" element={<OtherIncomePage />}/>
          <Route path="/summary" element={<SummaryPage />}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
