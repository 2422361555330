import React, {useState, useEffect} from 'react';
import { MDBDatatable } from 'mdb-react-ui-kit';

export function EquityTradesTable({country}) {
  const [advancedData, setAdvancedData] = useState({
    columns: [
      { label: 'ID', field: 'id', sort: false },
      { label: 'Data operacji', field: 'executionDate', sort: true },
      { label: 'Symbol', field: 'symbol', sort: false },
      { label: 'Operacja', field: 'operation', sort: false},
      { label: 'Liczba', field: 'quantity', sort: false},
      { label: 'Koszt jednostkowy', field: 'costPerUnit', sort: false },
      { label: 'Waluta', field: 'currency', sort: false},
      { label: 'Prowizje', field: 'fee', sort: false },
      { label: 'Data przeliczenia kursu', field: 'bookingDate', sort: false },
      { label: 'Kurs waluty', field: 'exchangeRatePln', sort: false },
      { label: 'Przepływ', field: 'cashFlow', sort: false },
      { label: 'Przepływ [PLN]', field: 'cashFlowPln', sort: false },
      { label: 'Zysk / Strate', field: 'gain', sort: false },
      { label: 'Zysk / Strate [PLN]', field: 'gainPln', sort: false },
      { label: 'Opis', field: 'description', sort: false },
    ],
    rows: [],
  });

  useEffect(() => {
    const trades = JSON.parse(localStorage.getItem('importEquityTrades'));
    const datatable = [];

    const printNumber = function(value) {
      return value === 0 ? '0.0' : value;
    };

    if (trades != null && trades[country] != null) {
      trades[country].transactions.forEach((singleTrade) => {
        datatable.push({
          id: singleTrade.id || '',
          executionDate: singleTrade.executionDate || '',
          symbol: singleTrade.symbol || '',
          operation: singleTrade.operation === 'BUY' ? 'Kupno' : 'Sprzedaż' || '',
          quantity: singleTrade.quantity || '',
          costPerUnit: printNumber(singleTrade.costPerUnit) || '',
          currency: singleTrade.currency || '',
          fee: printNumber(singleTrade.fee) || '',
          bookingDate: singleTrade.bookingDate || '',
          exchangeRatePln: singleTrade.exchangeRatePln || '',
          cashFlow: printNumber(singleTrade.cashFlow) || '',
          cashFlowPln: printNumber(singleTrade.cashFlowPln) || '',
          gain: printNumber(singleTrade.gain) || '',
          gainPln: printNumber(singleTrade.gainPln) || '',
          description: singleTrade.description || ''
        })
      });
    }
    setAdvancedData((prevData) => ({
      ...prevData,
      rows: datatable
    }));
  }, []);

  return (
    <MDBDatatable
      data={advancedData}
      loadingMessage='Ładowanie danych...'
      noFoundMessage='Brak wyników'
      ofText='z'
      rowsText='Wiersze na stronę'
      advancedSearch={(value) => {
        const string = value.split(' in:').map((str) => str.trim());
        const phrase = string[0];
        let columns = string[1];
        if (columns) {
          columns = columns.split(',').map((str) => str.toLowerCase().trim());
        }
        return { phrase, columns };
      }}
    />
  );
}
