import React, {useEffect, useState} from 'react';
import {
  MDBInput
} from 'mdb-react-ui-kit';
import '../assets/styles/import-form.css';

export function BondTradesSummary({country}) {
  const trades = JSON.parse(localStorage.getItem('importBondTrades'));
  var income = 0;
  var cost = 0;
  var gain = 0;
  if (trades != null) {
    income = trades[country].incomePln.toFixed(2);
    cost = trades[country].costPln.toFixed(2);
    gain = (income - cost).toFixed(2);
  }

  return (
    <div className='mb-4'>
      <div className='row my-4'>
        <div className='col-3 mt-2 pe-0'>Przychód [PLN]:</div>
        <div className='col-2 ps-0'><MDBInput id='income' type='number' value={income} readOnly={true}/></div>
      </div>
      <div className='row my-4'>
        <div className='col-3 mt-2 pe-0'>Koszt uzyskania [PLN]:</div>
        <div className='col-2 ps-0'><MDBInput id='cost' type='number' value={cost} readOnly={true}/></div>
      </div>
      <div className='row my-4'>
        <div className='col-3 mt-2 pe-0'>Dochód [PLN]:</div>
        <div className='col-2 ps-0'><MDBInput style={{backgroundColor: gain >= 0 ? 'lightgreen' : '#FFCCCB'}} id='gain' type='number' value={gain} readOnly={true}/></div>
      </div>
    </div>
  );
}
