import React from 'react';
import { LoginForm } from '../components/loginForm';

function Homepage() {
    if (localStorage.getItem('token') != null) {
        // user logged in
        window.location.href = '/import'
    }
    return (
      <>
        <LoginForm />
      </>
    );
}

export default Homepage;
